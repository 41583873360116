<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// import Stat from "./widget";
// import SalesAnalytics from "./sales-analytics";
// import Transaction from './transaction';
import Master from "@/apis/Master";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Stat,
    // SalesAnalytics,
    // Transaction
  },
  data() {
    return {
      title: "DASHBOARD",
      items: [
        {
          text: "B2B ADMIN",
        },
        {
          text: "DASHBOARD",
          active: true
        }
      ],
      dashboardData:"",
      countData:0,
      transactionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "images", sortable: false, label: "IMAGES",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "orderDate", sortable: false, label: "ORDER DETAILS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "firstName", sortable: false, label: "CUSTOMER",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "subTotal", sortable: false, label: "SUB TOTAL",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "discount", sortable: false, label: "DISCOUNT",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "total", sortable: false, label: "TOTAL",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "orderStatus", sortable: false, label: "ORDER STATUS",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION" ,  thStyle: { color: "black", "font-size":"16px"}}
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionData.length;

    Master.dashboard().then((res) => {
      console.log(res)
      this.dashboardData = res.data.data;
      this.transactionData = res.data.data;
    })

  },
  methods: {
    /**
     * Search the table data with search input
     */
    orderDetails(data){
      localStorage.setItem('orderId',data)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
<!--        <Stat />-->
        <template>
          <div class="row">
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TODAY ORDERS</p>
                      <router-link to="/open-order/order-list" title="CLICK HEAR TO VIEW ORDERS"><h4 class="mb-0">{{dashboardData.todayOrders}}</h4></router-link>
                    </div>
                    <br/>
                    <div class="text-primary gren_txt">
                     <!--  <i class="ri-shopping-cart-line" :class="`font-size-24`" style="color: lawngreen"></i> -->
                     {{dashboardData.todayOrders}}
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TODAY CANCELLATION</p>
                      <router-link to="/cancel-order/cancel-order-list" title="CLICK HEAR TO VIEW ORDERS"><h4 class="mb-0">{{dashboardData.todayCancelledOrders}}</h4></router-link>
                    </div>
                    <br/>
                    <div class="text-primary red_txt">
                    <!--   <i class=" fas fa-search" :class="`font-size-24`" style="color: red"></i> -->
                    {{dashboardData.todayCancelledOrders}}
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TODAY REVENUE</p>
                      <h4 class="mb-0" v-if="dashboardData.todayRevenue">QAR {{dashboardData.todayRevenue.toLocaleString()}}</h4>
                      <h4 class="mb-0" v-else>0</h4>
                    </div>
                    <br/>
                    <div class="text-primary">
                     <!--  <i class="ri-currency-line" :class="`font-size-24`"></i> -->
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">USERS</p>
                        <router-link to="/users">
                      <h4 class="mb-0" v-if="dashboardData.usersCount">{{dashboardData.usersCount }}</h4>
                      <h4 class="mb-0" v-else>0</h4>
                        </router-link>
                    </div>
                    <br/>
                    <div class="text-primary">
                     <!--  <i class="ri-currency-line" :class="`font-size-24`"></i> -->
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
          </div>
          <!-- end row -->
        </template>

        <template>
          <div class="row">
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TOTAL ORDERS</p>
                      <router-link to="/open-order/order-list" title="CLICK HEAR TO VIEW ORDERS"><h4 class="mb-0">{{dashboardData.totalOrders}}</h4></router-link>
                    </div>
                    <br/>
                    <div class="text-primary gren_txt">
                     <!--  <i class="ri-shopping-cart-line" :class="`font-size-24`" style="color: lawngreen"></i> -->
                     {{dashboardData.totalOrders}}
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TOTAL CANCELLATION</p>
                      <router-link to="/cancel-order/cancel-order-list" title="CLICK HEAR TO VIEW ORDERS"><h4 class="mb-0">{{dashboardData.totalCancelled}}</h4></router-link>
                    </div><br/>

                    <div class="text-primary red_txt">
                     <!--  <i class="ri-close-circle-line" :class="`font-size-24`" style="color: red"></i> -->
                     {{dashboardData.totalCancelled}}
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">TOTAL REVENUE</p>
                      <h4 class="mb-0" v-if="dashboardData.totalRevenue">QAR {{dashboardData.totalRevenue.toLocaleString()}}</h4>
                      <h4 class="mb-0" v-else>0</h4>
                    </div>
                    <br/>
                    <div class="text-primary">
                  <!--     <i class="ri-currency-line" :class="`font-size-24`"></i> -->
                    </div>
                  </div>
                </div>

<!--                <div class="card-body border-top py-3">-->
<!--                  <div class="text-truncate">-->
<!--                    <span class="badge badge-soft-success font-size-11">-->
<!--                      <i class="mdi mdi-menu-up"></i>-->
<!--                      29%-->
<!--                    </span>-->
<!--                    <span class="text-muted ml-2">From previous period</span>-->
<!--                  </div>-->
<!--                </div>-->
                <br/>
              </div>
            </div>
            
            <div class="col-md-3" >
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <p class="text-truncate font-size-14 mb-2">USER PAYMENT</p>
                      <router-link to="/user-payments">
                        <h4 class="mb-0" title="CLICK HEAR TO VIEW USER PAYMENTS" v-if="dashboardData.userPayments">{{dashboardData.userPayments}}</h4>
                        <h4 class="mb-0" title="CLICK HEAR TO VIEW USER PAYMENTS" v-else>0</h4>
                      </router-link>
                    </div>
                    <br/>
                    <div class="text-primary">
                    <!--   <i class="ri-currency-line" :class="`font-size-24`"></i> -->
                    </div>
                  </div>
                </div>

                <!--                <div class="card-body border-top py-3">-->
                <!--                  <div class="text-truncate">-->
                <!--                    <span class="badge badge-soft-success font-size-11">-->
                <!--                      <i class="mdi mdi-menu-up"></i>-->
                <!--                      29%-->
                <!--                    </span>-->
                <!--                    <span class="text-muted ml-2">From previous period</span>-->
                <!--                  </div>-->
                <!--                </div>-->
                <br/>
              </div>
            </div>
          </div>
          <!-- end row -->
        </template>


      </div>
<!--      <div class="col-xl-4">-->
<!--        <SalesAnalytics />-->
<!--      </div>-->
    </div>
    <div class="row">
      <div class="col-lg-12">
        <template>
          <div class="card">
            <div class="card-body" style="text-transform: uppercase !important;">
                          
              
<!--              <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">-->
<!--                <template v-slot:button-content>-->
<!--                  <i class="mdi mdi-dots-vertical"></i>-->
<!--                </template>-->
<!--                &lt;!&ndash; item&ndash;&gt;-->
<!--                <b-dropdown-item>Sales Report</b-dropdown-item>-->
<!--                &lt;!&ndash; item&ndash;&gt;-->
<!--                <b-dropdown-item>Export Report</b-dropdown-item>-->
<!--                &lt;!&ndash; item&ndash;&gt;-->
<!--                <b-dropdown-item>Profit</b-dropdown-item>-->
<!--                &lt;!&ndash; item&ndash;&gt;-->
<!--                <b-dropdown-item>Action</b-dropdown-item>-->
<!--              </b-dropdown>-->

              <h4 class="card-title mb-4">LATEST 5 ORDER</h4>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
<!--                    <label class="d-inline-flex align-items-center">-->
<!--                      Show&nbsp;-->
<!--                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries-->
<!--                    </label>-->
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    :items="transactionData.latestFiveOrders"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:cell(images)="image">
                    <div  v-if="image.item.products[0] == null">
                      <router-link to="/dashboard-product-details"><img
                          src="../../no-image.png"
                          @click="orderDetails(image.item.id)"
                          alt="product-img"
                          title="product-img"
                          class="b-avatar-lg"
                      /></router-link>
                    </div>
                    <div class="row" v-else>
                      <div class="col-md-2"  v-if="image.item.products.length > 1">
                        <router-link to="/dashboard-product-details"><img
                            @click="orderDetails(image.item.id)"
                            :src="image.item.products[0].imageUrl"
                            alt="product-img"
                            title="product-img"
                            class="b-avatar-lg"
                        /></router-link>
                      </div>
                      <div class="col-md-2"  v-else>
                        <router-link to="/dashboard-product-details"><img
                            :src="image.item.products[0].imageUrl"
                            @click="orderDetails(image.item.id)"
                            alt="product-img"
                            title="product-img"
                            class="b-avatar-lg"
                        /></router-link>
                      </div>
                      <div class="col-md-4 ml-5 mt-3" v-if="image.item.products.length > 1">
                        + {{0 ? image.item.products : image.item.products.length - 1}}
                      </div>
                    </div>

                  </template>
                  <template v-slot:cell(orderStatus)="row">
                    <div v-if="row.item.orderStatus == 1"
                         class="badge font-size-12 badge-soft-success"
                    >CONFIRMED</div>
                    <div v-if="row.item.orderStatus == 2"
                         class="badge font-size-12 badge-soft-warning"
                    >PARTIAL DELIVERED</div>
                    <div v-if="row.item.orderStatus == 3"
                         class="badge font-size-12 badge-soft-info"
                    >DELIVERED ALL</div>
                    <div v-if="row.item.orderStatus == 4"
                         class="badge font-size-12 badge-soft-danger"
                    >CANCELLED</div>
                  </template>
                  <template v-slot:cell(firstName)="row">
                    <ul class="pl-1" style="list-style-type: none;">
                      <li>
                        <div class="badge font-size-12" style="text-transform: uppercase !important;">
                         {{row.item.firstName}} {{row.item.lastName}}
                        </div>
                      </li>
                    </ul>
                  </template>
                  <template v-slot:cell(orderDate)="row">
                    <ul class="pl-1" style="list-style-type: none;">
                      <li>
                        <router-link to="/dashboard-product-details" > <div class="badge font-size-12"  @click="orderDetails(row.item.id)">
                          {{row.item.orderNum}}
                        </div></router-link>
                      </li>
                      <li>
                        <div class="badge font-size-12">
                         QTY : {{row.item.qty}}
                        </div>
                      </li>
                      <li>
                        <div class="badge font-size-12">
                          {{row.item.orderDate}}
                        </div>
                      </li>
                    </ul>
                  </template>
                  <template v-slot:cell(subTotal)="row">
                        <div class="badge font-size-12">
                          QAR {{row.item.subTotal.toLocaleString()}}
                        </div>
                  </template>
                  <template v-slot:cell(discount)="row">
                    <div class="badge font-size-12"  style="color: red">
                      QAR {{ (row.item.originalAmount - row.item.total).toLocaleString()}}
                    </div>
                  </template>
                  <template v-slot:cell(total)="row">
                    <div class="badge font-size-12">
                      QAR {{row.item.total.toLocaleString()}}
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/dashboard-product-details"><a
                        @click="orderDetails(data.item.id)"
                        href="javascript:void(0);"
                        class="mr-3 text-warning"
                        v-b-tooltip.hover
                        title="VIEW"
                    >
                      <i class="mdi mdi-eye font-size-18"></i>
                    </a></router-link>
                  </template>

<!--                  <template v-slot:cell(action)>-->
<!--                    <a-->
<!--                        href="javascript:void(0);"-->
<!--                        class="mr-3 text-primary"-->
<!--                        v-b-tooltip.hover-->
<!--                        data-toggle="tooltip"-->
<!--                        title="Edit"-->
<!--                    >-->
<!--                      <i class="mdi mdi-pencil font-size-18"></i>-->
<!--                    </a>-->
<!--                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">-->
<!--                      <i class="mdi mdi-trash-can font-size-18"></i>-->
<!--                    </a>-->
<!--                  </template>-->
                </b-table>
              </div>
<!--              <div class="row">-->
<!--                <div class="col">-->
<!--                  <div class="dataTables_paginate paging_simple_numbers float-right">-->
<!--                    <ul class="pagination pagination-rounded mb-0">-->
<!--                      &lt;!&ndash; pagination &ndash;&gt;-->
<!--                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>